import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

import Table from 'components/Table';
import Search from 'components/Search';
import PDFViewer from 'components/PDFViewer';
import InputErrors from 'components/InputErrors';
import Grid from 'components/Layout/Grid';
import AsideMenu from 'components/AsideMenu';
import EntityInfoBuiltInHeader from 'components/EntityInfoBuiltInHeader';

import {
  fetchCustomerDocuments,
  uploadDocument,
  clearDocumentList,
} from 'actions/customerDocument';
import { fetchCustomer } from 'actions/customer';

import { customersAppPath } from 'utils/paths';
import { documentsSearchFields } from 'utils/searchFields';
import { getBase64 } from 'utils/fileInputUpload';
import checkValidation from 'utils/validator';

import { documentsHead } from '../../../utils/tables';
import { headerLinks } from '../../../utils/links';
import { fileTypeValidation } from '../../../utils/validations';
import { isCompany } from 'utils/helpers';
import { COMPANY } from 'utils/constants';

class CustomerDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      preview: false,
      errors: '',
      fileType: '',
      list: [],
      query: {
        filters: {},
        search: {
          list: {},
          value: '',
        },
        page: 1,
        perPage: 20,
        sort: {
          field: '',
          order: '',
        },
      },
    };
  }

  componentDidMount() {
    const { list } = this.props.documents;
    const { data } = this.props.customer;
    const { query } = this.state;

    if (Object.keys(data).length === 0) {
      this.props.fetchCustomer(this.props.match.params.id);
    }

    if (list && list.length === 0) {
      this.props.fetchCustomerDocuments(this.props.match.params.id, query);
    }
  }

  componentWillUnmount() {
    this.props.clearDocumentList();
  }

  onChange = (e) => {
    const { id } = this.props.customer.data;
    const file = e.target.files[0];
    const errors = checkValidation(fileTypeValidation, {
      fileType: file.name.split('.').pop(),
    });

    if (Object.keys(errors).length === 0) {
      getBase64(file, (result) => {
        const document = {
          file: result,
          description: '',
          userId: id,
          filename: file.name,
        };

        this.props.uploadDocument(id, document);
      });
    }
    this.setState({
      errors,
    });
  };

  onSearch = (val) => {
    clearTimeout(this.timeout);

    const query = {
      ...this.state.query,
      search: {
        ...this.state.query.search,
        value: val,
        list: documentsSearchFields,
      },
      page: 1,
    };

    this.setState({ query });
    this.timeout = setTimeout(() => {
      this.updateList(query);
    }, 500);
  };

  onRowClick = (row) => {
    const { medium, original } = row.url;
    const { contentType } = row;
    const { file, preview } = this.state;

    this.setState({
      file: medium || original,
      preview: !(file === (medium || original) && preview),
      fileType: contentType,
    });
  };

  onPaginate = ({ selected }) => {
    const query = { ...this.state.query, page: selected + 1 };
    this.updateList(query);
    this.setState({ query });
  };

  updateList = (params) => {
    this.props.fetchCustomerDocuments(this.props.match.params.id, params);
  };

  render() {
    const rootPath = `${customersAppPath}/${this.props.match.params.id}`;
    const { file, preview, fileType } = this.state;
    const { list, creating, fetching, pagesTotal } = this.props.documents;
    const { data } = this.props.customer;
    const { firstName, lastName, refId, onfidoVerificationStatus } = data;
    const paginationClass = pagesTotal && pagesTotal > 1 ? '' : 'hidden';
    const searchValue = this.state.query.search.value;
    const loadingDataClass = `${fetching ? 'table-loader' : ''}`;
    const breadcrumbs = [
      {
        id: 1,
        pathTo: rootPath,
        value: `${firstName || ''} ${lastName || ''} ${refId || ''}`,
      },
    ];

    const paginationActiveClass = isCompany({ company: COMPANY.tie })
      ? 'active-tie'
      : 'active';

    return (
      <Fragment>
        <EntityInfoBuiltInHeader
          breadcrumbs={breadcrumbs}
          rootPath={rootPath}
          links={headerLinks}
          status={onfidoVerificationStatus}
        />

        <Grid.Main className="main-wrapper-with-aside">
          <Grid.Content>
            <Grid.Controls>
              <div className="">
                <h2 className="h2">Customer documents</h2>
              </div>
              <div className="flex direction-column">
                <div className="flex items-center justify-center">
                  <Search
                    type="text"
                    name="search"
                    placeholder="Search files..."
                    wrapperClass="search"
                    className="search-input"
                    onChange={this.onSearch}
                    value={searchValue}
                  />

                  <div className="flex direction-column justify-center items-center overflow-hidden relative">
                    <label
                      htmlFor="loadPfg"
                      className="btn btn-success flex justify-center items-center plus"
                    >
                      {creating ? (
                        <div className="loader" />
                      ) : (
                        <span>+ Upload new</span>
                      )}
                      <input
                        id="loadPfg"
                        type="file"
                        onChange={this.onChange}
                      />
                    </label>
                  </div>
                </div>
                <InputErrors errors={this.state.errors.fileType} />
              </div>
            </Grid.Controls>

            <div className="table-list-holder flex">
              <Table
                className={`table-сlaimtech ${
                  preview ? 'col-xs-7' : 'col-xs-12'
                }`}
                tHead={documentsHead}
                tBody={list}
                onRowClick={this.onRowClick}
                onSortChange={this.onSortChange}
                trClassName={loadingDataClass}
                loading={fetching}
              />
              {preview && (
                <PDFViewer
                  url={file}
                  classWrap="pdf-single col-xs-5"
                  classContent=""
                  navClass=""
                  fileType={fileType}
                />
              )}
            </div>

            <ReactPaginate
              pageCount={pagesTotal}
              pageRangeDisplayed={pagesTotal}
              marginPagesDisplayed={10}
              breakLabel={<span>...</span>}
              nextLabel={
                <i className="la-icon la-icon-arrow la-icon-arrow-left" />
              }
              previousLabel={
                <i className="la-icon la-icon-arrow la-icon-arrow-right" />
              }
              breakClassName={'break-me'}
              activeClassName={paginationActiveClass}
              containerClassName={`${paginationClass} pagination flex justify-center landing`}
              subContainerClassName={'pages pagination'}
              onPageChange={this.onPaginate}
            />
          </Grid.Content>

          <Grid.Aside>
            <AsideMenu id={this.props.match.params.id} />
          </Grid.Aside>
        </Grid.Main>
      </Fragment>
    );
  }
}

CustomerDocuments.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchCustomerDocuments: PropTypes.func.isRequired,
  documents: PropTypes.shape({
    list: PropTypes.array,
    creating: PropTypes.bool,
    fetching: PropTypes.bool,
    pagesTotal: PropTypes.number,
  }).isRequired,
  uploadDocument: PropTypes.func.isRequired,
  clearDocumentList: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    data: PropTypes.shape({
      refId: PropTypes.string,
    }),
  }).isRequired,
  fetchCustomer: PropTypes.func.isRequired,
};

CustomerDocuments.defaultProps = {};

const mapStateToProps = (state) => ({
  documents: state.customerDocument,
  customer: state.customer,
});

const mapDispatchToProps = {
  fetchCustomerDocuments,
  uploadDocument,
  clearDocumentList,
  fetchCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDocuments);
