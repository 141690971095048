import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { rootPath } from '../utils/paths';
import { loginAppPath } from 'utils/paths';
import { accountFormClasses } from '../utils/constants';
import { fetchSession, endSession, deleteSession } from 'actions/session';

import Grid from 'components/Layout/Grid';
import Button from 'components/Button';

import AccountForm from '../components/AccountForm';
import CustomerAccountForm from '../components/CustomerAccountForm';

import {
  defineColorStyle,
  getTransparentBtnColor,
  isCustomerRole,
  isThompsons,
} from 'utils/helpers';

class AccountDetails extends Component {
  logOut = () => {
    this.props.deleteSession();
    this.props.endSession();
    this.props.history.push(loginAppPath);
    window.location.reload();
  };

  render() {
    const { settings, session, account, browser } = this.props;
    const { data } = session;
    const { fields } = account;
    const { roles } = data;
    const isMobile = browser.windowSize[0] < 576;

    const btnColorStyle = defineColorStyle('botMsgTextColor', settings);
    const transparentBtnColorStyle = getTransparentBtnColor({ btnColorStyle });

    return (
      <Fragment>
        <Grid.Main className="height-auto">
          <Grid.Content className="mb20">
            <Grid.Controls>
              <div
                className={`flex justify-between container-fluid ${
                  isMobile ? 'direction-column' : ''
                }`}
              >
                <div>
                  <h2 className="h2">
                    {isThompsons()
                      ? 'My details'
                      : isCustomerRole(roles)
                      ? 'Customer details'
                      : 'Account details'}
                  </h2>
                </div>
                <div>
                  <Button
                    type="soft"
                    value="Log out"
                    onClick={this.logOut}
                    className="color-red"
                    style={transparentBtnColorStyle}
                  />
                  <Button
                    type="success"
                    value="Edit"
                    pathTo={`${rootPath}/edit`}
                    style={btnColorStyle}
                  />
                </div>
              </div>
            </Grid.Controls>

            <div
              className={`${
                isThompsons() ? 'accountThompsons' : ''
              } container-fluid flex row col-xs-8 justify-start mt0 mb20 col-sd-12`}
            >
              {isCustomerRole(roles) ? (
                <CustomerAccountForm
                  className={accountFormClasses}
                  disabled
                  values={fields}
                />
              ) : (
                <AccountForm
                  className={accountFormClasses}
                  disabled
                  values={fields}
                />
              )}
            </div>
          </Grid.Content>
        </Grid.Main>
      </Fragment>
    );
  }
}

AccountDetails.propTypes = {
  session: PropTypes.shape({
    data: PropTypes.object,
  }),
  fetchSession: PropTypes.func.isRequired,
};

AccountDetails.defaultProps = {
  session: {},
};

const mapDispatchToProps = {
  fetchSession,
  endSession,
  deleteSession,
};

const mapStateToProps = ({ browser, settings }) => ({
  browser,
  settings,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
